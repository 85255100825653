<template>
  <div class="vip-privilege">
    <div class="head">
      <div class="title">会员解锁记录</div>
    </div>
    <div class="privilege-content">
      <div class="head-desc">
        <div class="desc-item second">解锁渠道</div>
        <div class="desc-item item-free">获得奖励</div>
      </div>
      <div class="privilege-detail" v-if="unlockList.length > 0">
        <div class="item-list" v-for="(item, index) in unlockList" :key="index"
          :class="(index + 1) % 2 === 0 ? 'active' : ''">
          <div class="item time">{{ item.channel }}</div>
          <div class="item name ">
            <svg-icon iconClass="vip_normal"></svg-icon>
            <div class="day">会员{{ item.day }}</div>
          </div>
        </div>
      </div>
      <div class="none-record" v-else>暂无解锁记录</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    unlockList: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="scss" scoped>
.vip-privilege {
  background-color: #fff;
  border-radius: 24px;
  max-width: 860px;
  margin: 0 auto;
  padding-bottom: 24px;

  .head {
    border-bottom: 1px solid #F7F5EE;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .title {
      font-size: 28px;
      font-weight: 500;
      color: #131415;
      line-height: 40px;
      margin-top: 7px;
    }
  }

  .privilege-content {
    .head-desc {
      height: 88px;
      display: flex;
      align-items: center;

      .desc-item {
        flex: 1;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        color: #232222;
      }

      .item-free {
        color: #FFA800;
      }

      .second {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 100%;

        .vip-svg {
          position: absolute;
          top: 11px;
        }

        .img {
          width: 82px;
          height: 82px;
          position: absolute;
          left: 0;
          top: 0;
        }

        .name {
          font-size: 20px;
          font-weight: 500;
          color: #FFA800;
          line-height: 28px;
        }
      }
    }

    .privilege-detail {
      .item-list {
        display: flex;
        align-items: center;
        height: 44px;
        background-color: rgba(216, 216, 216, 0);

        .item {
          flex: 1;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;

          &.day {
            opacity: .6;
          }
        }

        &.active {
          background-color: rgba(248, 235, 210, .2);
        }

        .name {
          font-size: 14px;
          font-weight: 500;
          color: #232222;
        }
      }
    }
    .none-record {
      text-align: center;
      font-size: 18px;
      color: #232222;
    }
  }
}
</style>