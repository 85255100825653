<template>
  <div class="vip-compare-box">
    <div class="vip-user-info">
      <img src="../../assets/images/cricle.png" alt="" class="circle-small-left">
      <img src="../../assets/images/vip_popup.png" alt="" class="vip-popup">
      <div class="user-center-info">
        <img :src="$store.state.userInfo.avatar" />
        <div class="info-content">
          <div class="info-name">
            <div class="info">
              <div class="name">{{ $store.state.userInfo.nickname }}</div>
              <div class="display-flex vip-type-box" v-if="isVip">
                <svg-icon iconClass="vip_small" class="w-14 h-14" style="margin-left:7px;position: relative;top: -1px;">
                </svg-icon>
                <div class="vip-type">{{ vipType($store.state.userInfo.vip.vipDateType) }}VIP</div>
              </div>
            </div>
          </div>
          <div class="vip-delay-time" v-if="$store.state.userInfo.vip.vipExpire !== null">
            {{ vipType($store.state.userInfo.vip.vipDateType) }}VIP有效期：{{ $store.state.userInfo.vip.vipExpire }}
          </div>
          <div class="vip-delay-time" v-else>开通会员即刻享受会员权益</div>
          <div class="mg-t-10 cursor-pointer"
            style="color: rgba(35, 34, 34, .6);font-weight: 500;text-decoration: underline" @click="openExchangeCode">
            兑换码</div>
        </div>
      </div>
      <div class="right-btn">
        <div class="xf-btn" @click="openPurchase">
          <template v-if="!isVip">
            付费开通
          </template>
          <template v-else>
            续费{{ vipType($store.state.userInfo.vip.vipDateType) }}VIP
          </template>
        </div>
        <!-- <el-button type="primary" class="free-btn" @click="openUnlockVip">免费解锁</el-button> -->
      </div>
    </div>
    <div class="vip-privilege-container">
      <div class="head">
        <img src="../../assets/images/cricle.png" alt="" class="circle-big-right">
        <img src="../../assets/images/vip_contrast_bg@2x.png" alt="" class="w-196">
        <div class="title">会员权限对比</div>
      </div>
      <div class="vip-privilege-box">
        <img src="../../assets/images/cricle.png" alt="" class="circle-small-right">
        <!-- <div class="circle-big-left"></div> -->
        <div class="left flex-1 pd-b-24">
          <div class="left-head h-88 lh-88 text-align-center">功能权限</div>
          <div class="left-item" v-for="(item, index) in list" :key="index"
            :class="(index + 1) % 2 === 0 ? 'active' : ''">
            {{ item.title }}
            <span v-show="item.desc" class="item-loading-text">(即将上线)</span>
          </div>
        </div>
        <div class="center left flex-1">
          <div class="center-head left-head h-88 lh-88 text-align-center">
            <img src="../../assets/images/vip_mark_zunxiang_82pt@2x.png" alt="" class="img">
            <svg-icon iconClass="vip_normal" class="w-24 h-24 vip-svg"></svg-icon>
            <div class="name">月度/年度VIP</div>
          </div>
          <div class="left-item center-item" v-for="(item1, index1) in list" :key="index1"
            :class="(index1 + 1) % 2 === 0 ? 'active' : ''">
            <svg-icon iconClass="has_true" style="color:#ffb700;width: 18px;height: 18px;"
              v-if="(item1.vip).indexOf('√') > -1"></svg-icon>
            <div v-else>{{ item1.vip }} </div>
          </div>
        </div>
        <div class="left flex-1 pd-b-24">
          <div class="free h-88 lh-88 text-align-center left-head">免费版</div>
          <div class="left-item center-item" v-for="(item2, index2) in list" :key="index2"
            :class="(index2 + 1) % 2 === 0 ? 'active' : ''">
            <svg-icon iconClass="has_close" style="color:rgba(19, 20, 21, 0.4000);width: 12px;height: 12px;"
              v-if="(item2.free).indexOf('×') > -1"></svg-icon>
            <svg-icon iconClass="has_true" style="color:#ffb700;width: 18px;height: 18px;"
              v-else-if="(item2.free).indexOf('√') > -1"></svg-icon>
            <div v-else>{{ item2.free }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { showMessage } from '../../libs/message'
import request from '../../libs/api.request'

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    openExchangeCode() {
      this.$prompt('', '兑换会员', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: '',
        inputPlaceholder: '请输入兑换码',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm' && instance.inputValue) {
            request.request({
              url: '/vip/exchange',
              method: 'post',
              data: {
                code: instance.inputValue
              },
            }).then(res => {
              showMessage({ message: '兑换成功', type: "success" })
              done()
              let t = setTimeout(() => {
                location.reload();
                clearTimeout(t)
              }, 300)
            }).catch(e => {
              showMessage({ message: e.message, type: "error" })
            })
          } else {
            done();
          }
        }
      })
    },
    openPurchase() {
      if (this.$store.state.userInfo.vip.vipDateType === 'forever') {
        showMessage({ message: '您已成为永久会员,无需继续购买！', type: 'warning' })
        return
      }
      this.$emit('openPurchase')
    },
    openUnlockVip() {
      this.$emit('openUnlockVip')
    }
  },
  computed: {
    ...mapGetters(['isVip']),
    vipType() {
      return (type) => {
        if (type === 'year') {
          return '年度'
        } else if (type === 'month') {
          return '月度'
        } else if (type === 'forever') {
          return '永久'
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.vip-compare-box {
  position: relative;

  .vip-user-info {
    position: relative;
    max-width: 800px;
    height: 142px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url(../../assets/images/vip_big_bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 16px;
    z-index: 9;

    .circle-small-left {
      position: absolute;
      left: -183px;
      top: 39px;
      width: 62px;
      height: 62px;
    }

    .vip-popup {
      position: absolute;
      right: 40px;
      top: -72px;
      width: 92px;
      height: 92px;
    }

    .user-center-info {
      border-radius: 16px 16px 0 0;
      display: flex;
      margin-left: 24px;

      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
      }

      .info-content {
        margin-left: 24px;
        flex: 1;

        .info-name {
          margin: 20px 0;
          display: flex;
          justify-content: space-between;
          font-weight: 600;
          font-size: 20px;
          line-height: 20px;
          color: #00000a;

          .xf {
            font-size: 12px;
            font-weight: 500;
            color: #131415;

            &:hover {
              cursor: pointer;
            }
          }

          .info {
            display: flex;

            .vip-type-box {
              align-items: center;
              background: url(../../assets/images/vip_bg-mark.png);
              background-size: contain;
              background-repeat: round;
              width: 72px;
              margin-left: 8px;

              .vip-type {
                color: #FBDE94;
                font-size: 12px;
                font-weight: 600;
                transform: scale(0.9);
              }
            }
          }
        }

        .vip-delay-time {
          font-size: 14px;
          color: #131415;
          line-height: 17px;
        }
      }
    }

    .right-btn {
      display: flex;
      margin-right: 24px;

      .xf-btn {
        width: 102px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: linear-gradient(125deg, #000000 0%, #262525 100%);
        border-radius: 10px;
        font-size: 14px;
        font-weight: 600;
        color: #FBDE94;

        &:hover {
          cursor: pointer;
        }
      }

      .free-btn {
        width: 102px;
        height: 36px;
        background: #1DC236;
        border-radius: 10px;
        border: 1px solid;
        margin-left: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .vip-privilege-container {
    max-width: 860px;
    border-radius: 24px;
    margin: 0 auto;
    background-color: #fff;

    .head {
      position: relative;
      border-bottom: 1px solid #F7F5EE;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .circle-big-right {
        position: absolute;
        width: 265px;
        height: 265px;
        top: -85px;
        right: -160px;
      }

      .title {
        font-size: 28px;
        font-weight: 500;
        color: #131415;
        line-height: 40px;
        margin-top: 7px;
      }
    }

    .vip-privilege-box {
      display: flex;
      margin-bottom: 16px;
      position: relative;
      z-index: 9;

      .circle-small-right {
        position: absolute;
        top: 200px;
        right: -110px;
        width: 60px;
        height: 60px;
      }

      .circle-big-left {
        position: absolute;
        top: 200px;
        left: -110px;
        background: linear-gradient(233deg, rgba(255, 218, 144, 0) 0%, #FFC34D 100%);
        opacity: 0.5;
        width: 190px;
        height: 190px;
        border-radius: 50%;
      }

      .left {
        .left-head {
          font-size: 20px;
          font-weight: 500;
          color: #232222;
        }

        .free {
          // color: #FFA800;
          color: rgba(35, 34, 34, .6) !important;
        }

        .left-item {
          position: relative;
          font-size: 14px;
          font-weight: 500;
          color: #232222;
          height: 44px;
          line-height: 44px;
          text-align: center;

          .item-loading-text {
            position: absolute;
            right: 0;
            translate: -100% 0;
            font-size: 12px;
            transform: scale(0.9);
            color: rgba(19, 20, 21, 0.4000);
          }

          &.active {
            background-color: rgba(248, 235, 210, .2);
          }
        }

        .center-item {
          color: #FFA800;
        }
      }

      .center {
        box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1100);

        .center-head {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .img {
            position: absolute;
            top: 0;
            left: 0;
            width: 82px;
            height: 82px;
          }

          .vip-svg {
            position: absolute;
            top: 11px;
          }

          .name {
            font-size: 20px;
            font-weight: 500;
            color: #FFA800;
          }
        }
      }
    }
  }
}
</style>