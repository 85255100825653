<template>
  <div class="introduce">
    <div class="mode"></div>
    <div class="introduce-content">
      <div class="head">
        <div class="title">{{title}}</div>
        <img src="../../assets/images/vip_popup_left_bg@2x.png" alt="" class="bg-img">
        <img src="../../assets/images/vip_popup_right_bg@2x.png" alt="" class="bg-img">
        <img src="../../assets/images/vip_popup.png" alt="" class="vip-img" v-if="showSubmitBtn">
        <svg-icon iconClass="vip_close" class="close-img" @click="onClose"></svg-icon>
      </div>
      <slot></slot>
      <div class="display-flex justify-content-center mg-b-30" v-if="showSubmitBtn">
        <el-button type="primary" class="submit-btn" @click="onClick">立即开通会员</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default ({
  props:{
    title:{
      type: String,
      default: '选择会员套餐'
    },
    showSubmitBtn:{
      type: Boolean,
      default: true
    }
  },
  methods: {
    onClick() {
      this.$emit('submitClick')
    },
    onClose() {
      this.$emit('closeClick')
    }
  },
})
</script>
<style lang="scss" scoped>
.introduce {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  background: rgba(32, 32, 32, 0.6000);

  .introduce-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 608px;
    // height: 444px;
    background: linear-gradient(360deg, #F7F7F7 0%, #FFF9EB 100%);
    border-radius: 16px;

    .head {
      height: 98px;

      .title {
        position: absolute;
        left: 50%;
        top: 44px;
        transform: translateX(-50%);
        font-size: 20px;
        font-weight: 600;
        color: #00000A;
        z-index: 9;
      }

      .bg-img {
        position: absolute;
        left: 0;
      }

      .vip-img {
        position: absolute;
        left: 50%;
        top: -105px;
        transform: translateX(-50%);
        width: 130px;
        height: 130px;
        object-fit: cover;
      }

      .close-img {
        position: absolute;
        right: 18px;
        top: 18px;
        width: 20px;
        height: 20px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  
    .submit-btn {
      width: 358px;
      height: 44px;
      background: #FFA800;
      border-radius: 8px;
      font-size: 15px;
    }
  }
}
</style>