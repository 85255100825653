<template>
  <div class="relate-kf-box">
    <div class="box-content">
      <div class="title">
        <div class="hide flex-1">11</div>
        <div class="text">
          扫码添加客服
        </div>
        <div class="text1" @click="closeDialog">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <img src="../../../public/static/image/wxqun.jpg" alt="" class="img">
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    }
  },
}
</script>
<style lang="scss" scoped>
.relate-kf-box {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999999;
  background: rgba(32, 32, 32, 0.6000);

  .box-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;
    border-radius: 12px;
    background-color: #fff;
    width: 400px;
    // height: 400px;

    .title {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;

      .hide {
        visibility: hidden;
      }

      .text {
        font-size: 18px;
        color: #202020;
        font-weight: 600;
        flex: 1;
      }

      .text1 {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        font-size: 20px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .img {
      width: 100%;
      height: 100%;
    }
  }
}</style>