<template>
  <div class="vip-box">
    <!-- <img src="../assets/images/cricle.png" alt="" class="circle-big-right"> -->
    <div class="vip-head">
      <div class="left" @click="onBackClick">
        <svg-icon iconClass="arrow_left" class="w-24 h-24"></svg-icon>
        <div class="text">返回上一步</div>
      </div>
      <div class="vip-logo">
        <img src="../assets/images/vip_logo.png" alt="" class="w-40 h-40 mg-r-6">
        <div class="title">闪记卡会员</div>
      </div>
    </div>
    <VipCompare v-show="showCompare" @openPurchase="openPurchase" @openUnlockVip="openUnlockVip" :list="introList">
    </VipCompare>
    <UnlockRecord v-show="showCompare" :unlockList="unlockList"></UnlockRecord>
    <div class="kefu" v-show="showCompare" @click="dialogVisible = true">遇到问题，联系惊叹闪卡笔记客服</div>
    <PurchaseVip v-if="showPurchaseVip" @closeClick="closePurchaseVip" :skuData="skuData"></PurchaseVip>
    <FreeUnlockVip v-show="showUnlockVip" :unlockList="unlockList" @openKF="dialogVisible = true"></FreeUnlockVip>
    <RelateKF v-if="dialogVisible" @closeDialog="dialogVisible = false"></RelateKF>
  </div>
</template>
<script>
import PurchaseVip from "../components/vip/PurchaseVip.vue"
import FreeUnlockVip from "../components/vip/FreeUnlockVip.vue"
import UnlockRecord from '../components/vip/UnlockRecord.vue'
import VipCompare from "../components/vip/VipCompare.vue"
import RelateKF from "../components/vip/RelateKF.vue"
import request from '../libs/api.request'
export default ({
  components: {
    PurchaseVip,
    FreeUnlockVip,
    UnlockRecord,
    VipCompare,
    RelateKF
  },
  data() {
    return {
      dialogVisible: false,
      showPurchaseVip: false,
      showCompare: true,
      showUnlockVip: false,
      introList: [],
      unlockList: [],
      skuData: {},
      arr: [
        // {
        //   title: '历史备份',
        //   vip: '√',
        //   free: '×',
        //   desc:'（即将上线）'
        // },
        {
          title: '回收站',
          vip: '√',
          free: '×',
        },
      ]
    }
  },
  methods: {
    // 会员套餐
    getVipSku() {
      request.request({
        url: '/vip/sku'
      }).then(res => {
        console.log(res);
        this.skuData = res
      })
    },
    getVipUnlockList() {
      request.request({
        url: '/vip/unlockRecord'
      }).then(res => {
        this.unlockList = res.list
      })
    },
    getVipIntroduce() {
      request.request({
        url: '/vip/intro'
      }).then(res => {
        console.log(res);
        this.introList = res.list.concat(this.arr)
      })
    },
    openPurchase() {
      this.showPurchaseVip = true
    },
    openUnlockVip() {
      this.showCompare = false
      this.showUnlockVip = true
    },
    closePurchaseVip() {
      this.showPurchaseVip = false
    },
    onBackClick() {
      if (this.showUnlockVip) {
        this.showCompare = true
        this.showUnlockVip = false
      } else {
        this.$router.go(-1)
      }
    },
  },
  created() {
    this.getVipIntroduce()
    this.getVipUnlockList()
    this.getVipSku()
    if (Object.keys(this.$route.query).length > 0 && this.$route.query.from == '1') {
      this.showUnlockVip = true
      this.showCompare = false
    }
  },
})
</script>
<style lang="scss" scoped>
.vip-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  background: linear-gradient(360deg, #F6F6F6 0%, #FFE8B7 100%);
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
  }

  .circle-big-right {
    position: absolute;
    right: 180px;
    width: 265px;
  }

  .vip-head {
    display: flex;

    .left {
      position: absolute;
      display: flex;
      align-items: center;
      margin-top: 24px;
      margin-left: 40px;

      &:hover {
        cursor: pointer;
      }
    }

    .vip-logo {
      display: flex;
      align-items: center;
      width: 800px;
      margin: 0 auto;
      margin-top: 16px;

      .title {
        font-size: 24px;
        font-weight: 600;
        color: #202020;
      }
    }
  }

  .kefu {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #FFA800;
    line-height: 17px;
    margin: 20px 0;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>