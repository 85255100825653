<template>
  <VipIntroduceBox @closeClick="closeClick" :title="convertData[currentId].title" :showSubmitBtn="false">
    <div class="condition-box">
      <div class="left">
        <div class="desc">第一步：{{ convertData[currentId].step1 }}</div>
        <div class="desc">第二步：{{ convertData[currentId].step2 }}</div>
        <div class="desc" v-if="convertData[currentId].condition">要求：{{ convertData[currentId].condition }}</div>
        <div class="desc">第三步：扫描二维码添加客服，发送任务截图，客服核实后发放会员奖励</div>
      </div>
      <div class="right">
        <img src="../../../public/static/image/wxqun.jpg" alt="" class="kf-img">
      </div>
    </div>
  </VipIntroduceBox>
</template>
<script>
import VipIntroduceBox from "./VipIntroduceBox.vue"

export default {
  props: {
    currentId: {
      type: Number,
      default: 1
    }
  },
  components: {
    VipIntroduceBox
  },
  data() {
    return {
      convertData: {
        1: {
          title: '小红书种草',
          step1: "打开【小红书】",
          step2: "发布一篇关于【惊叹闪卡】的种草笔记/视频",
        },
        2: {
          title: '分享惊叹至朋友圈',
          step1: "打开【微信】",
          step2: "分享【惊叹闪卡】到朋友圈",
          condition: "配文30字以上、公开分享、分享记录需存活12小时以上"
        },
        3: {
          title: '微信群/QQ群种草',
          step1: "打开【微信群/QQ群】",
          step2: "在群聊中推荐【惊叹闪卡】",
          condition: "群人数大于50人、消息存活10分钟以上"
        },
        4: {
          title: '应用市场好评',
          step1: "打开【应用市场/App Store】",
          step2: "5星+文字评价【惊叹闪卡】",
        },
        5: {
          title: '知乎回答种草',
          step1: "打开【知乎】",
          step2: "在学习、考研、APP、笔记等话题下推荐【惊叹闪卡】",
        },
        6: {
          title: 'B站种草',
          step1: "打开【哔哩哔哩】",
          step2: "发布【惊叹闪卡】种草视频",
        },
        7: {
          title: '抖音种草',
          step1: "打开【抖音】",
          step2: "发布【惊叹闪卡】种草视频",
        },
        8: {
          title: '长文章种草、测评',
          step1: "打开【任意公众号/知乎/少数派】等软件",
          step2: "发布使用【惊叹闪卡】的种草/测评文章",
          condition: "文章需原创"
        },
      },
    }
  },
  methods: {
    closeClick() {
      this.$emit('closeClick')
    }
  },
}
</script>

<style lang="scss" scoped>
.condition-box {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  padding-top: 0;

  .left {
    flex: 1;
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .desc {
      border-radius: 8px;
      padding: 8px;
      background-color: #fff;
      color: #202020;
      line-height: 30px;
    }
  }

  .right {
    border-radius: 12px;

    .kf-img {
      border-radius: 12px;
      width: 250px;
      height: 100%;
    }
  }
}</style>