<template>
  <div>
    <VipIntroduceBox @submitClick="submitClick" @closeClick="closeClick">
      <div class="content">
        <div class="vip-item" :class="currentIndex === index ? 'active' : ''" @click="vipTypeClick(item, index)"
          v-for="(item, index) in skuData.sku" :key="item.id">
          <div class="money">{{ item.price / 100 }} <span>元</span></div>
          <div class="time">{{ item.desc }}</div>
        </div>
      </div>
      <div class="desc" v-html="skuData.info"></div>
    </VipIntroduceBox>
    <payType v-if="showPay" @closePayType="closePayType" :selectSku="selectSku"></payType>
  </div>
</template>
<script>
import VipIntroduceBox from "./VipIntroduceBox.vue"
import payType from './payType.vue'

export default {
  props: {
    skuData: {
      type: Object,
      default: () => { }
    }
  },
  components: {
    VipIntroduceBox,
    payType
  },
  data() {
    return {
      currentIndex: 0,
      showPay: false,
      selectSku: {
        id: 2
      }
    }
  },
  methods: {
    closePayType() {
      this.showPay = false
    },
    submitClick() {
      this.showPay = true
    },
    vipTypeClick(item, index) {
      this.selectSku = item
      this.currentIndex = index
    },
    closeClick() {
      this.$emit('closeClick')
    }
  },
  created() {
    this.selectSku = this.skuData.sku[this.skuData.sku.length - 1]
    this.currentIndex = this.skuData.sku.length - 1
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 0 40px;
  display: flex;
  justify-content: space-between;

  .vip-item {
    width: 160px;
    height: 180px;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.0500);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 3px solid transparent;

    &:hover {
      cursor: pointer;
    }

    &.active {
      border-color: #FFA800;
    }

    .money {
      font-size: 34px;
      font-weight: 600;
      color: #131415;

      span {
        font-size: 16px;
      }
    }

    .time {
      font-size: 16px;
      color: rgba(0, 0, 10, 0.6000);
      line-height: 22px;
      margin-top: 12px;
    }
  }
}

.desc {
  padding: 0 132px;
  font-size: 12px;
  color: rgba(0, 0, 10, 0.4000);
  line-height: 22px;
  text-align: center;
  margin: 24px 0;

  span {
    color: rgba(0, 0, 10, 0.8000);
  }
}
</style>