<template>
  <div>
    <div class="free-vip-box">
      <div class="head">
        <div class="title">免费解锁会员</div>
        <img src="../../assets/images/vip_popup_right_bg@2x.png" class="bg-img">
        <img src="../../assets/images/vip_popup_left_bg@2x.png" class="bg-img">
        <el-button class="add-btn" @click="openKF">添加客服兑换会员</el-button>
      </div>
      <div class="record-list">
        <div class="list-item" v-for="item in convertList" :key="item.id"
          :style="{ opacity: item.isJoin ? '.8' : '1' }">
          <div class="item-top">
            <div class="item-left">
              <img :src="item.url" alt="" class="img">
              <div class="item-detail">
                <div class="title" :style="{ color: item.isJoin ? 'rgba(34,34,34,0.2000)' : '' }">{{ item.name }}</div>
                <div class="time" :style="{ color: item.isJoin ? 'rgba(34,34,34,0.2000)' : '' }">{{ item.time }}</div>
              </div>
            </div>
            <el-button type="primary" class="right-btn" :disabled="item.isJoin"
              :class="[item.isJoin ? 'btn-bgColor' : '']" @click="onClick(item.id)">
              {{ item.isJoin ? '已兑换' : '参与' }}</el-button>
          </div>
          <div class="item-desc" :style="{ color: item.isJoin ? 'rgba(34,34,34,0.2000)' : '' }">
            {{ item.desc }}
          </div>
        </div>
      </div>

    </div>
    <div class="footer">
      <div class="text1">完成任务后，将任务截图发给客服，核实后发放会员奖励！</div>
      <div class="text2">《惊叹闪卡笔记》对该活动页面有最终解释权</div>
    </div>
    <FreeUnlockCondition @closeClick="closeClick" v-if="showCondition" :currentId="currentId"></FreeUnlockCondition>
  </div>
</template>
<script>
import FreeUnlockCondition from './FreeUnlockCondition.vue'
export default {
  props: {
    unlockList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    FreeUnlockCondition
  },
  data() {
    return {
      currentId: 1,
      showCondition: false,
      convertList: [
        { name: '小红书种草', time: '可兑换5天～永久会员', isJoin: false, desc: '在小红书发布闪记卡的种草笔记或视频，我们会根据内容质量奖励5-60天、每1个互动数据（点赞、收藏）奖励0.2天的会员；最高可奖励永久会员', id: 1, url: require('../../assets/images/vip/redbook.png') },
        // { name: '应用市场好评', time: '可兑换10天会员', isJoin: false, desc: '在应用商店上给惊叹闪卡五星带文字好评，即可截图给客服领取奖励', id: 4, url: require('../../assets/images/vip/store.png') },
        { name: '分享惊叹至朋友圈', time: '每次兑换10天会员[上限10次]', isJoin: false, desc: '分享惊叹闪卡APP到朋友圈，并配文30字以上，需公开分享，发布12小时候后截图给客服领取奖励', id: 2, url: require('../../assets/images/vip/wechat.png') },
        { name: '微信群/QQ群种草', time: '每个群奖励10天会员[上限6个群]', isJoin: false, desc: '在跟学习、考试、笔记、学生有关的微信/QQ群用自己语言推荐惊叹闪卡，要求群人数大于50人，消息存活10分钟', id: 3, url: require('../../assets/images/vip/group.png') },
        { name: '知乎回答种草', time: '可兑换5天～永久会员', isJoin: false, desc: '在知乎回答一个与学习、考研、APP、笔记等凡是跟惊叹闪卡有关的问答，根据回答质量和数据表现，奖励5天～永久的惊叹会员', id: 5, url: require('../../assets/images/vip/zhihu.png') },
        { name: 'B站种草', time: '可兑换5天～永久会员', isJoin: false, desc: '在哔哩哔哩发布一个惊叹闪卡种草视频，我们会根据内容质量和数据，奖励5天～永久的惊叹会员', id: 6, url: require('../../assets/images/vip/bzhan.png') },
        { name: '抖音种草', time: '可兑换5天～永久会员', isJoin: false, desc: '在抖音发布一个惊叹闪卡种草视频，我们会根据内容质量和数据，奖励5天～永久的惊叹会员', id: 7, url: require('../../assets/images/vip/douyin.png') },
        { name: '长文章种草、测评', time: '可兑换5天～永久会员', isJoin: false, desc: '在公众号、知乎、少数派等媒体原创发表惊叹闪卡的种草、测评文章，根据回答质量和数据表现，奖励5天～永久的惊叹会员', id: 8, url: require('../../assets/images/vip/article.png') },
      ]
    }
  },
  methods: {
    openKF() {
      this.$emit('openKF')
    },
    closeClick() {
      this.showCondition = false
    },
    onClick(id) {
      this.currentId = id
      this.showCondition = true
    }
  },
  watch: {
    unlockList(newList) {
      if (newList.length > 0) {
        for (let i = 0; i < this.convertList.length; i++) {
          for (let j = 0; j < newList.length; j++) {
            if (this.convertList[i].id === newList[j].channelType) {
              this.convertList[i].isJoin = true
            }
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.free-vip-box {
  position: relative;
  max-width: 860px;
  margin: 0 auto;
  margin-top: 26px;
  background: linear-gradient(360deg, #F6F6F6 0%, #FFF9EB 100%);
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.0500);
  border-radius: 24px;

  .head {
    height: 82px;

    .title {
      position: absolute;
      left: 50%;
      top: 30px;
      transform: translateX(-50%);
      font-weight: 500;
      color: #00000A;
      z-index: 9;
      text-align: center;
      font-size: 28px;
      line-height: 18px;
    }

    .bg-img {
      position: absolute;
      display: block;
      height: 82px;
      width: 100%;
    }

    .add-btn {
      position: absolute;
      right: 24px;
      top: 16px;
      width: 144px;
      height: 44px;
      background: linear-gradient(125deg, #000000 0%, #262525 100%);
      border-radius: 10px;
      color: #FBDE94;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .record-list {
    padding: 0 24px;
    padding-bottom: 8px;

    .list-item {
      background-color: #fff;
      height: 122px;
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.0500);
      border-radius: 12px;
      padding: 24px;
      margin-bottom: 16px;

      .item-top {
        display: flex;
        justify-content: space-between;

        .item-left {
          display: flex;

          .img {
            width: 44px;
            height: 44px;
            margin-right: 12px;
          }

          .item-detail {
            flex: 1;

            .title {
              font-size: 16px;
              font-weight: 500;
              color: #00000A;
              line-height: 22px;
            }

            .time {
              font-size: 12px;
              font-weight: 500;
              color: #FFA800;
              line-height: 17px;
              margin: 4px 0 10px;
            }


          }
        }

        .right-btn {
          width: 64px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          font-weight: 400;

          &.btn-bgColor {
            background: rgba(0, 0, 10, 0.3000) !important;
            border-color: transparent !important;
          }
        }
      }

      .item-desc {
        font-size: 12px;
        color: rgba(0, 0, 10, 0.6000);
        line-height: 19px;
        margin-left: 56px;
      }


    }
  }
}

.footer {
  margin-top: 16px;
  text-align: center;

  .text1 {
    font-size: 12px;
    color: #FFA800;
    line-height: 17px;
  }

  .text2 {
    margin-top: 4px;
    margin-bottom: 16px;
    font-size: 12px;
    color: rgba(0, 0, 10, 0.4000);
    line-height: 22px;
  }
}
</style>