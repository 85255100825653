<template>
  <div class="pay-box">
    <template v-if="beforPay">
      <template v-if="!qrCodeUrl">
        <div class="dialog-box">
          <div class="comment-grade">
            <div class="hide">11</div>
            <div class="text">支付方式</div>
            <svg-icon iconClass="vip_close" class="close-img" @click="onClose"></svg-icon>
          </div>
          <div class="pay-type">
            <div class="pay-type-container" :class="currentIndex === index ? 'active' : ''"
              v-for="(item, index) in payTypeList" @click="handleSelectPayMode(item, index)" :key="item.payMode">
              <img :src="item.img" class="w-48 h-48 bdr-12">
              <div class="text mg-l-20">{{ item.text }}</div>
            </div>
          </div>
          <div class="dialog-footer">
            <el-button type="primary" class="w-300 mg-t-50 bdr-8" @click="onSubmitClick" style="color:#fff;font-size: 15px;">确 认</el-button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="dialog-box">
          <div class="justify-content-center">
            <img :src="qrCodeUrl" class="w-200 h-200">
          </div>
          <div class="text-align-center pd-20">请使用微信扫码进行支付</div>
          <div class="justify-content-center">
            <el-button class="mg-t-20 w-300" type="primary" @click="qrCodeUrl = ''" style="color:#fff">取 消</el-button>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="dialog-box">
        <div class="comment-grade justify-content-center">是否支付完成？</div>
        <div class="dialog-footer">
          <el-button @click="onPayDownClick" class="cancelBtn cancel-btn">未支付</el-button>
          <el-button type="primary" @click="onPayDownClick" class="cancelBtn" style="color:#fff">我已支付</el-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import request from '../../libs/api.request'
import { showMessage } from '../../libs/message'
export default {
  props: {
    selectSku: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      loading: false,
      currentIndex: 0,
      payTypeList: [
        {
          payMode: 'alipay_web',
          img: require('../../assets/images/vip/zfbpay.jpg'),
          text: '支付宝'
        },
        {
          payMode: 'wxpay_qr',
          img: require('../../assets/images/vip/wxpay.jpg'),
          text: '微信'
        }
      ],
      payMode: 'alipay_web',
      beforPay: true,
      qrCodeUrl: '',
      orderId: ''
    }
  },
  methods: {
    onClose() {
      this.$emit('closePayType')
    },
    onPayDownClick() {
      location.reload()
    },
    checkOrder() {
      setTimeout(() => {
        if (this.qrCodeUrl) {
          request.request(
            {
              url: '/vip/checkOrder',
              params: {
                orderId: this.orderId
              }
            }).then(res => {
              console.log(res);
              if (res.status === 2) {
                location.reload();
              } else {
                this.checkOrder();
              }
            }).catch(e => {
              this.checkOrder();
            })
        }
      }, 800);
    },
    onSubmitClick() {
      if (this.payMode === '') {
        return showMessage({ message: '请选择支付方式', type: 'warning' })
      }
      let loading = this.$loading({
        text: '支付加载中...'
      })
      this.loading = true
      request.request({
        url: '/vip/order',
        method: "post",
        data: {
          skuId: this.selectSku.id,
          href: location.href,
          payMode: this.payMode,
        }
      }).then(res => {
        console.log(res);
        if (this.payMode == 'wxpay_qr') {
          loading.close()
          this.qrCodeUrl = res.payData.codeUrl;
          console.log(this.qrCodeUrl);
          this.orderId = res.payData.orderId;
          this.checkOrder();
        } else {
          this.orderId = res.orderId
          setTimeout(() => {
            var a = document.createElement('a');
            a.setAttribute('href', res.payUrl);
            a.setAttribute('target', '_blank');
            a.setAttribute('id', 'payLink');
            // 防止反复添加
            if (!document.getElementById('payLink')) document.body.appendChild(a);
            a.click();
            // window.open(res.data.payUrl) // 这样可能会被浏览器拦截
            loading.close()
            this.beforPay = false
          }, 500)
        }
      }).catch(err => {
        loading.close()
        showMessage({ message: err.message, type: "error" })
      })
    },
    handleClose(done) {
      done()
      this.beforPay = true
    },
    handleSelectPayMode(item, index) {
      this.payMode = item.payMode
      this.currentIndex = index
    },
  },
  mounted() {
    this.qrCodeUrl = '';
  },
  beforeDestroy() {
    this.qrCodeUrl = '';
  }
}
</script>

<style lang="scss" scoped>
.pay-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;
  background: rgba(32, 32, 32, 0.6000);

  .dialog-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 482px;
    padding: 30px 24px;
    border-radius: 12px;

    .comment-grade {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #00000A;
      font-size: 24px;
      margin-bottom: 40px;
      width: 100%;

      .hide {
        visibility: hidden;
      }

      .text {
        font-weight: 500;
      }

      .close-img {
        width: 22px;
        height: 22px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    ::v-deep .el-rate__icon {
      font-size: 42px;
    }

    .pay-type {
      display: flex;
      justify-content: space-between;

      .pay-type-container {
        padding: 24px 30px;
        display: flex;
        align-items: center;
        border: 1px solid transparent;
        border-radius: 12px;
        &:nth-child(1){
          margin-right: 12px;
        }

        &.active {
          border: 1px solid #FFB700;
        }

        &:hover {
          cursor: pointer;
        }

        .text {
          font-size: 20px;
          font-weight: 600;
          color: #202020;
        }
      }
    }
  }

  .cancelBtn {
    width: 201px;
    height: 48px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    border-radius: 8px;
    border: 1px solid rgba(32, 32, 32, 0.16);

    &.cancel-btn {
      background-color: #fff;
      color: #202020;
    }
  }
}
</style>
